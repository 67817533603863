const langList = {
    Yin: {
        lang: "Yin",
        hello: "Apa kabar!",
        today: "Hair ini",
        dayword: "Sepatah kata sehari",
        holiday: "Hari libur",
        holidaySub: "Liburan masih jauh..",
        confirm: "Yakin",
        day: "langit"
    },
    Xi: {
        lang: "Xi",
        hello: "¡Cómo estás!",
        today: "Hoy",
        dayword: "Una palabra al día",
        holiday: "Día festivo",
        holidaySub: "Las vacaciones aún están lejos...",
        confirm: "Cierto",
        day: "cielo"
    },
}
export const getLang = () => {
    let obj = langList['Yin']
    let customLang = import.meta.env && import.meta.env.VITE_CUSTOM_LANG
    let lang
    if (customLang) {
        lang = customLang.split("=")?.[1]
        obj = langList[lang]
    }
    return obj

}